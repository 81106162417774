import React from 'react'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import HubspotForm from 'react-hubspot-form'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="columns">
                <div className="column">
                  <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                      <li><span>Human Automation Blog</span></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="columns is-multiline">
                <div className="column is-4">
                  <h1
                    className="has-text-weight-bold "
                    style={{
                      lineHeight: '1.2em',
                      fontSize: '30px'
                    }}
                  >
                    Catch Up on the Latest AI Marketing News, Trends and Technologies
                  </h1>
                </div>
                <div className="column is-offset-2">
                    <div className="blog-roll-email-capture">
                      <HubspotForm
                        portalId='1987452'
                        formId='891bf576-b3ac-418b-8a1f-4643cf237687'                      
                        loading={<div>Loading...</div>}
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="section">
          <div className="container">
            <div className="content blog-roll">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

